import React,{useState} from 'react'
import "./VideoContent.css"
import { useNavigate } from 'react-router-dom';
import Modal from "../Modal/Modal";

function VideoContent({
    heading,
    subHeading="",
    postSubHeading="",
    postHeading="",
    postContent="",
    content,
    btn=false,
    btnText,
    btnRef="/",
    shiftRow=false,
    img,
    reviewContent= false,
    review,
    reviewUser,
    userImg,
    mobileTextCenter=true,
    videoSrc
  }) {
    
    const [modal, setModal] = useState(false);
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
    const navigate = useNavigate()

    const toggleModal = (e) => {
        setModal((prev)=>{
            return !prev
        });
    };
    return (
      <div className='video-content-section standard-padding-space'>
        {modal ? <Modal status={modal} closeModal={toggleModal} videoSrc={videoSrc} /> : <></>}
        <div className='container'>
          <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
            <div className={`col-lg-6 p-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
              <img src={img} className='img-fluid' style={{cursor:"pointer"}} onClick={()=>{setModal(true)}} alt='video thumbnail'/>
            </div>
            <div className='col-lg-6'>
              <div className={`text-content ${mobileTextCenter && "text-center text-lg-start"} `}>
                <p className='sub-heading mb-0'>{subHeading}</p>
                <div className='paddLef'>
                  <h3 className='body-heading my-2 mt-3 '>{heading}</h3>
                  <p className='sub-heading mb-3 mb-xxl-4' style={{fontSize:"35px",fontWeight:"600"}}>{postSubHeading}</p>
                  <div className=''>{paragraphs}</div>
                  <h3 className='body-heading mt-3 my-xl-3 mt-xl-4'>{postHeading}</h3>
                  <p className='body-paragraph my-1 mb-3'>{postContent}</p>
                  {btn && <button className='genral-btn mt-4' onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                </div>
                {
                  reviewContent &&
                  <>
                    <div className='line-seprator my-2 mt-4'></div>
                    <div className='d-flex align-items-start gap-3 pt-4'>
                      <img src={userImg} alt='user '/>
                      <div className='my-3'>
                        <p className='body-paragraph mb-1'>{review}</p>
                        <p className='body-paragraph mb-0'>{reviewUser}</p>
                      </div>
                    </div>
                    <button className='genral-btn mt-3' onClick={()=>{navigate("/about")}}>ABOUT US</button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default VideoContent
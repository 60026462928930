import React from 'react'
import { useNavigate } from 'react-router-dom'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function BlogCard({
    content,
    img,
    title,
    anchor,
    redirectionFunction
}) {

  const breifPara = truncateText(content,120)
  const breifTitle = truncateText(title,50)

  return (
    <div className='blog-card mb-5' onClick={()=>{redirectionFunction(anchor)}}>
       
        <img src={img} className='card-img my-3'alt='card-img'/>
        <h3 className='body-heading text-md-start text-center my-3' style={{fontSize:"30px"}}>{breifTitle}</h3>
        <div className='body-paragraph text-md-start text-center' dangerouslySetInnerHTML={{ __html: breifPara }}></div>
        
       
    </div>
  )
}

export default BlogCard
import React,{useState} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TextCard from "../component/TextCard/TextCard"
import { Helmet } from 'react-helmet'


const optimizedContentList = [
    {
        title:"1- Routine system maintenance to prolong the smooth operation of your IT infrastructure: ",
        content:"We proactively maintain your systems to prevent unexpected issues and costly downtime"
    },
    {
        title:"2- Making informed projections about future IT spending requirements based on both your current situation and anticipated growth strategies: ",
        content:"We help you plan for the future, so you can allocate your resources wisely and prevent unexpected financial surprises."
    },
    {
        title:"3- Addressing hidden costs related to technical debt, which can arise due to outdated technology and increase the time needed for processes: ",
        content:"We help you avoid the hidden costs associated with using outdated technology, making your business more efficient."
    },
]

const cardContent1 = [
    {
        title:"Expertise that exceeds all expectations",
        breifPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can ",
        actualPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can tackle any IT challenge that comes your way."
    },
    {
        title:"Lightning-fast response times to your IT needs",
        breifPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your ",
        actualPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your problems are addressed promptly, minimizing downtime and its associated costs."
    },
    {
        title:"Proactive IT strategies that are tailored to your specific requirements",
        breifPara:"We don’t just react to IT problems; we ",
        actualPara:"We don’t just react to IT problems; we anticipate them and develop strategies to prevent them. This proactive approach keeps your business running smoothly."
    },
    {
        title:"Unprecedented peace of mind for you and your business",
        breifPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that",
        actualPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that your IT infrastructure is in capable hands. We take care of the tech so you can focus on growing your business."
    },
]

const cardContent2 = [
    {
        title:"Reduced downtime thanks to proactive business continuity and disaster recovery services",
        actualPara:"We ensure your business can recover swiftly from any unexpected disruptions, minimizing downtime and its associated costs."
    },
    {
        title:"Mitigated vulnerabilities through the use of cybersecurity tools and constant monitoring",
        actualPara:"We prioritize your cybersecurity, making sure your data and systems are protected from potential threats."
    },
    {
        title:"Enhanced data protection using top-tier solutions at an affordable price",
        actualPara:"We provide enterprise-level data protection solutions at a cost-effective rate, ensuring your business’s critical data is safeguarded."
    },
]

const textDropDownList = [
    {
        title:"Network monitoring and management",
        content:"We keep a watchful eye on your network 24/7 to detect and address issues before they cause downtime."
    },
    {
        title:"Comprehensive business continuity planning",
        content:"We help you develop robust strategies to ensure your business can continue running smoothly even in the face of unexpected disruptions."
    },
    {
        title:"Off-site/on-site data backup and disaster recovery services",
        content:"We safeguard your data and ensure it can be recovered quickly in the event of a data loss or disaster."
    },
]

function ManagedService() {
    const [slideStatus , setSlideStatus] = useState(false)
  return (
    <div className="home-page">
         <Helmet>
          <title>Managed IT Services in Toronto | HQNS IT Solutions</title>
          <meta name='description' content="Discover how HQNS's Managed IT Services in Toronto can elevate your business with expert support, proactive strategies, and unmatched peace of mind." />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Managed IT Services in Toronto"></meta>
        </Helmet>
      <HeroBanner 
      bgEffect={true}
        subHeading=""
        Heading="Managed IT Service"
        postSubHeading="Unlocking Business Potential with HQNS’s Managed IT Services in Toronto"
        nextLineSpanHeading=""
        Paragraph="In today’s ever-evolving business landscape, staying ahead of IT challenges is paramount for success. If you’ve ever felt the weight of the tech world on your shoulders, HQNS has a solution for you. We introduce our top-tier **Managed IT in Toronto**, setting us apart as the ultimate IT solution provider. In this article, we delve into the importance of such services and how they can take your business to new heights."
        bgImg="/images/creatives/managed-it-hero-bg.jpg"
        btn={false}
        slideState={(slide)=>{setSlideStatus(slide)}}
      />

<div className='left-gradient'>
      <Sponsor
        offOnSlide={slideStatus}
    />

    <TextCard
        heading='The Distinct Advantages of HQNS’s Managed IT Services'
        content='When you choose HQNS, you gain access to a multitude of advantages, including:'
        cardContent={cardContent1}
    />
</div>

<div className='left-gradient-upper'>
      <TextContent
         heading= "Unprecedented peace of mind for you and your business"
         subHeading=""
         content={`One of the many advantages of HQNS’s **Managed IT Services in Toronto** is the ability to set contractual terms and service-level agreements (SLAs) for your IT tasks. This eliminates the need to spend time, money, and resources on finding, hiring, or training your in-house IT staff. With HQNS, you only pay for the coverage or services you actually use, reducing costs associated with full-time employees and their related expenses. We conduct quarterly IT performance and satisfaction reviews to ensure all aspects of our agreements are met, and our goals remain aligned with your business objectives.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/managed-text-1.png"
         reviewContent={false}
      />
      
      <div className='right-gradient'> 

      <TextContent
         heading= "Scalability and Flexibility to Match Your Ambitions"
         subHeading=""
         content={`HQNS’s scalability means that we can grow with you and provide more bandwidth, service hours, and expertise than you need. This adaptability is crucial if you have immediate plans for significant infrastructure changes, such as a migration to the cloud. HQNS ensures that any growth your company experiences can be supported without compromising performance or coverage.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/managed-text-2.png"
         reviewContent={false}
      />

      <TextContent
         heading= "Optimized IT Budgeting"
         subHeading=""
         content={`Costs associated with information technology in businesses can quickly spiral out of control. There are many hidden costs related to managing your organization’s evolving infrastructure`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/managed-text-3.png"
         reviewContent={false}
         titleList={true}
         titleListContent={optimizedContentList}
      />
      </div>
</div>

<div className='left-gradient'> 

    <TextCard
         dropDown={false}
         heading="Strengthening Data Protection"
         content="Many managed service providers continually invest in their technology to stay competitive. When companies hire such providers to deliver their IT services, they often benefit from the use of advanced technologies. This results in"
         cardContent={cardContent2}
    />
</div>

<div className='right-gradient'> 
    <TextContent
         heading= "Improved Efficiency"
         subHeading=""
         content={`Outsourcing IT tasks to managed services like HQNS frees up your in-house team to focus on more profitable activities. HQNS often provides guidelines and recommendations to help optimize infrastructure performance, which can lead to increased productivity and improved ROI for your business.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/managed-text-4.png"
         reviewContent={false}
      />
    <TextContent
         heading= "Minimizing Network Downtime"
         subHeading=""
         content={`The managed IT services provided by HQNS are not bound by the same schedule as your other employees. This means we can frequently carry out system updates and maintenance during off-peak hours. We offer services such as:`}
         btn={false}
         shiftRow={true}
         img="/images/creatives/managed-text-5.png"
         reviewContent={false}
         dropDown={true}
         dropDownList={textDropDownList}
      />
</div>
    

      <Footer/>
</div>
  )
}

export default ManagedService
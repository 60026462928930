import React,{useState} from 'react'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import "./SideBar.css"
import { useNavigate } from 'react-router-dom';

function SideBar({
    stateSide=true
}) 
{
    const [q1, setQ1] = useState("no");
    const [q2, setQ2] = useState("");
    const [q3, setQ3] = useState("");
    const [q4, setQ4] = useState("");
    const [q5, setQ5] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-plan-quote", {
            q1: q1,
            q2:q2,
            q3:q3,
            q4:q4,
            q5:q5,
            formType: "Build your IT Plan form",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Plan sended Successfully!");
              setLoading(false);
              setQ1("");
              setQ2("");
              setQ3("");
              setQ4("");
              setQ5("");
              navigate("/thank-you");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Failed to send Plan, try again by reloading the page.");
              setLoading(false);
              setQ1("");
              setQ2("");
              setQ3("");
              setQ4("");
              setQ5("");
            }
          });
      };
      const hadleq1 = (event) => {
        setQ1(event.target.value);
      };
  return (
    <div className={`SideBar-container ${stateSide ? "show" : "hide"} `}>
        <div className='text-content' >
            <h4>Tailored Solutions, Just a Click Away</h4>
            <h6 >Build Your IT Plan:</h6>
        </div>
        <form className="content " onSubmit={formSubmitHandler}>

             <div className="col-12">
                <p className='inp-tit'>Is there an existing IT team at your company?</p>
                <div className='d-flex flex-row gap-4'>

                    <label className='d-flex align-items-center gap-2' style={{color:"white"}}>
                        <input
                        type="radio"
                        value="yes"
                        checked={q1 === 'yes'}
                        onChange={hadleq1}
                        className='mx-1'
                        />
                        Yes
                    </label>
                    <label className='d-flex align-items-center gap-2' style={{color:"white"}}>
                        <input
                        type="radio"
                        value="no"
                        checked={q1 === 'no'}
                        onChange={hadleq1}
                        className='mx-1'
                        />
                        No
                    </label>
                </div>
            </div>
            <div className="col-12">
                <p className='inp-tit'>How many operational locations does your business have?</p>
              <input
                required
                type="number"
                value={q2}
                maxLength="36"
                min="0"
                onChange={(e) =>{e.target.value<0 ? setQ2(0) : setQ2(e.target.value)}}
              />
            </div>
            <div className="col-12">
                <p className='inp-tit'>What is the current number of servers in use?</p>
              <input
                required
                type="number"
                value={q3}
                maxLength="36"
                min="0"
                onChange={(e) =>{e.target.value<0 ? setQ3(0) : setQ3(e.target.value)}}
              />
            </div>
            <div className="col-12">
                <p className='inp-tit'>How many users are in need of support?</p>
              <input
                required
                type="number"
                value={q4}
                maxLength="36"
                min="0"
                onChange={(e) =>{e.target.value<0 ? setQ4(0) : setQ4(e.target.value)}}
              />
            </div>
            <div className="col-12">
                <p className='inp-tit'>What volume of data requires backup? (in Terabytes)</p>
              <input
                required
                type="number"
                value={q5}
                maxLength="36"
                min="0"
                onChange={(e) =>{e.target.value<0 ? setQ5(0) : setQ5(e.target.value)}}
              />
            </div>
            <div className="col-12 mt-4 button_col d-flex align-items-center justify-content-start gap-1">
                      <button type="submit" className="genral-btn">Submit
                      </button>
                      <a href="tel:+16474794288"><button type='button' className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"500"}}  >
                (647) 479 4288
                </button></a>
                      {loading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "#2893BD",marginLeft:"10px" }}
                          />
                    ) : (
                      success ? (
                        <div>
                          {setLoading(false)}
                          {setSuccess(false)}
                        </div>
                      ) : (
                        error && (
                          <div>
                            {setLoading(false)}
                            {alert(error)}
                            {setError(false)}
                          </div>
                        )
                      )
                    )}
                      
                    </div>

        </form>
    </div>
  )
}

export default SideBar
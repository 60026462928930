import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import DiscoverServices from "../component/DiscoverServices/DiscoverServices"
import DropDownContent from '../component/DropDownContent/DropDownContent'
import Contactform from '../component/Contact Form/Contactform'
import {Helmet} from "react-helmet"

function Home() {
  const [slideStatus , setSlideStatus] = useState(false)
  return (
    <div className="home-page">
        <Helmet>
          <title>HQNS: Empowering Businesses with Innovative cutting-edge managed IT Solutions</title>
          <meta name='description' content="Discover HQNS—a leader in cutting-edge managed IT solutions. With 10+ years of experience, our team offers reliable, client-centered expertise for your business growth" />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="HQ Network Solutions"></meta>
        </Helmet>
      <HeroBanner 
        subHeading=""
        Heading="Empowering Businesses With Cutting-Edge IT Solutions"
        nextLineSpanHeading=""
        Paragraph="HQ Networks – Where Passion meets Professionalism in Technology Support"
        btnText="Let's Connect"
        bgImg="/images/creatives/home-hero-bg.jpg"
        btn={true}
        btnRef="/contact"
        bgEffect={true}
        bgVideo={true}
        slideState={(slide)=>{setSlideStatus(slide)}}
      />
  <div className='left-gradient'>
      <Sponsor
        offOnSlide={slideStatus}
      />
      <TextContent
         heading= "HQ Network Solutions"
         subHeading="About US"
         content="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence, bringing to the fore solutions that are both innovative and grounded in seasoned expertise. At HQNS, we welcome you to experience a partnership that speaks the language of uncompromising quality and unwavering dedication to your business growth."
         btn={true}
         btnText="About Us"
         btnRef='/about'
         shiftRow={true}
         img="/images/creatives/home-text-1.webp"
         reviewContent={false}
      />
</div>
<div className='left-gradient-upper'>
      <DiscoverServices
        heading='Services We Provide For You'
        subHeading='OUR SERVICES'
        content='In a world continuously shaped and reshaped by technological advancements, having a reliable IT partner can be your key to staying ahead. At HQNS, we bring to you over 40 years of experience in carving out IT pathways that are innovative, secure, and perfectly aligned with your business goals'
      />
<div className='right-gradient'> 
      <DropDownContent
        shiftRow={false}
        img="/images/creatives/home-dropdownconent-1.webp"
        mobileTextCenter={true}
      />

      <VideoContent
            heading="End-to-End Solutions"
            subHeading=""
            content="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence"
            btn={true}
            shiftRow={true}
            btnText="Let's Connect"
            btnRef="/contact"
            img="/images/creatives/home-video-thumbnail-image-1.webp"
            reviewContent={false}
            videoSrc={"/images/HQNS-Services-HORIZONTAL-HESAM-1.mp4" }
        />
</div>
</div>
<div className='bottom-gradient'>
      <Testimonials/>
      
      <CardCarousel
        subHeading="OUR NEWS"
        heading="Most Popular Blogs Publications"
        content={"In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach"}
      />

      <Contactform/>

      <Footer/>

      </div>
</div>
  )
}

export default Home
import React from 'react'
import "./TextCard.css"
import TextCardBox from './TextCardBox'


function TextCardSimpleBox({
    title,
    actualPara
})
{
    return (
        <div
          className="text-card-box p-4 px-5 d-flex flex-column align-items-start"
          
        >
          <div className="content-info d-flex gap-3 align-items-center justify-content-center text-center my-3">
            <h5 className="title">{title}</h5>
          </div>
          <p
            className={`body-paragraph text-center`}
            style={{ lineHeight: "1.8rem" }}
          >
            {actualPara}
          </p>
        </div>
      );
}


function TextCard({
    dropDown=true,
    heading="",
    content="",
    cardContent=[]
}) {
  return (
    <div className='text-card-container standard-padding-space'>
        <div className='container '>
            <div className='text-content text-center'>
                <p className='sub-heading'></p>
                <h3 className='body-heading'>{heading}</h3>
                <p className='body-paragraph px-3 mt-3'>{content}</p>
            </div>
            <div className='container-fluid mt-5'>
                <div className='row gy-3'>
                    {
                        cardContent.map((ele)=>{
                            return(
                            <>
                                {
                                    dropDown?
                                    <div className='col-xxl-3 col-lg-6 col-12'>
                                        <TextCardBox
                                            title={ele.title}
                                            breifPara={ele.breifPara}
                                            actualPara={ele.actualPara}
                                        />
                                    </div>
                                    :
                                    <div className='col-lg-4 col-12'>
                                    <TextCardSimpleBox
                                        title={ele.title}
                                        actualPara={ele.actualPara}
                                    />
                                    </div>
                                }
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextCard
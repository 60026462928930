import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import BlogsPortalListings from "../../component/Blogs & Landing Page/BlogsPortalListings";
import HeroBanner from "../../component/Common Banner Component/HeroBanner";

const BlogsScreen = () => {
  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeroBanner
                Heading="Blogs Listing"
                Paragraph={"HQNS Blogs Portal"}
                // bgImg={"/images/creatives/home-hero-bg.jpg"}
            />
      {/* <h1 style={{ color: "White" }} className="h1_main ps-5 pt-5 pb-5">
        Blogs Listing
      </h1> */}
      <BlogsPortalListings />
    </Fragment>
  );
};

export default BlogsScreen;

import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'

function About() {
    return (
      <div className="home-page">
         <Helmet>
          <title>HQNS - Reliable IT Solutions & Expertise | 40+ Years Experience</title>
          <meta name='description' content='Explore HQNS, your trusted partner in technology solutions with over 40 years of experience. Discover reliable, client-centered IT services tailored for your business growth. Contact us now!' />
          <link rel="canonical" href="" />
        </Helmet>
        <HeroBanner 
          subHeading=""
          Heading="About Us"
          nextLineSpanHeading=""
          Paragraph="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence,"
          btnText=""
          bgImg="/images/creatives/about-hero-bg.jpg"
          btn={false}
          btnRef="/"
          bgEffect={true}
          isCheveron={true}
        />
   <div className='left-gradient'>
        <TextContent
           heading= "About HQNS Expertise"
           subHeading="About US"
           content="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence, bringing to the fore solutions that are both innovative and grounded in seasoned expertise. At HQNS, we welcome you to experience a partnership that speaks the language of uncompromising quality and unwavering dedication to your business growth."
           btn={true}
           btnText="Connect Now"
           btnRef='/contact'
           shiftRow={true}
           img="/images/creatives/about-text-1.png"
           reviewContent={false}
        />
  </div>
  <div className='left-gradient-upper'>
        <div className='mt-5 mb-5 container'>
            <div className='text-content text-center'>
                <h2 className='body-heading'>The HQNS Advantage</h2>
                <p className='sub-heading'>A proven history of delivering successful IT solutions to Canadian businesses</p>

            </div>
        </div>
        <div className='right-gradient'> 
        <TextContent
           heading= "Four Decades of Technological Mastery Tailored for You"
           subHeading=""
           content="In a digital landscape that is in a perpetual state of evolution, HQNS stands as a beacon of stability and innovation, powered by over 40 years of collective experience in the technology field. Our journey is characterized not just by the extensive years but by a repertoire of knowledge and agile strategies that have kept pace with the ever-changing industry dynamics. This wealth of experience makes us your trusted partner in delivering modern, robust, and secure IT solutions tailored to meet your unique business needs."
           btn={true}
           btnText="Let's Connect"
           btnRef='/contact'
           shiftRow={false}
           img="/images/creatives/about-text-2.png"
           reviewContent={false}
        />
        <TextContent
           heading= "Your Vision, Amplified through Best-of-Breed Technologies"
           subHeading=""
           content="What sets HQNS apart from the multitude is our uncompromising commitment to realizing your vision through the optimal use of best-of-breed technologies. We deviate from the conventional vendor-centric approach, choosing instead to remain impassioned advocates for technology that works. Before any solution reaches you, it undergoes a stringent evaluation process to ensure it stands tall on the parameters of efficiency, relevance, and reliability."
           btn={true}
           btnText="Let's Connect"
           btnRef='/contact'
           shiftRow={true}
           img="/images/creatives/about-text-3.png"
           reviewContent={false}
        />
        </div>
   </div>
   <div className='left-gradient'>
        <TextContent
           heading= "Demystifying Technology, One Solution at a Time"
           subHeading=""
           content="In a realm cluttered with technical jargon and a plethora of options, making an informed choice can often be overwhelming. HQNS steps in as your trusted advisor, untangling the complex web of terms to present you with solutions that are both comprehensible and perfectly aligned with your business objectives. We take it upon ourselves to sift through the noise, ensuring the choices we present are future-proof, fostering a seamless growth trajectory for your business."
           btn={true}
           btnText="Let's Connect"
           btnRef='/contact'
           shiftRow={false}
           img="/images/creatives/about-text-4.png"
           reviewContent={false}
        />
        <TextContent
           heading= "A Partnership Grounded in Active Listening and Mutual Growth"
           subHeading=""
           content="The cornerstone of our approach is a deep-seated respect and understanding of your needs. At HQNS, we engage in active and empathetic listening to craft solutions that are not only secure but resonate deeply with your business ethos. Our commitment to putting our customers first has seen us evolve in step with industry shifts, always staying ahead to meet and exceed your expectations."
           btn={true}
           btnText="Let's Connect"
           btnRef='/contact'
           shiftRow={true}
           img="/images/creatives/about-text-5.png"
           reviewContent={false}
        />
        <TextContent
           heading= "Embark on a Journey with HQNS"
           subHeading=""
           content={"Your business is not just another project for us; it is a voyage we undertake together towards a horizon of success, innovation, and growth. As your partners, we are relentless in our pursuit of excellence, driven to prioritize your needs above all.\n Discover a partnership that promises growth rooted in understanding, and solutions forged in expertise. Welcome to HQNS – where we build tomorrow, today."}
           btn={true}
           btnText="Let's Connect"
           btnRef='/contact'
           shiftRow={false}
           img="/images/creatives/about-text-6.png"
           reviewContent={false}
        />
  </div>
<div className='right-gradient'>
        <Testimonials/>
        
        <CardCarousel
          subHeading="OUR NEWS"
          heading="Most Popular Blogs Publications"
          content={"In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach"}
        />
  
  
        <Footer/>
        </div>
  </div>
    )
  }
  
  export default About
import "./Testimonials.css"
import React from "react";
import TestimonialCard from "./TestimonialCard";
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


function Testimonials() {
  const blogData = [
    {clientName:"Ahmed R",clientReview:"Mr. Sohail has helped me with many IT problems for the last couple of years. He's always quick to respond when an issue arises. He's incredibly dedicated and passionate about his work."},
    {clientName:"Ali Syed CPA",clientReview:"The staff at HQ is highly knowlidgeable and very professional. They are alway willing to go the extra mile to ensure we get the best possible service."},
    {clientName:"Brian Turpin",clientReview:"Greenwin has been working with HQNS for approximately 4 years now. Whether it be an office move, Firewall upgrade, emergency weekend calls or just to discuss the latest industry trends; Sohail and his team have provided us with the support we need.  As technology continues to be the core of our business, they are a vital part of our success."},
  ]

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="w-100 container px-0 my-3">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2">reviews</p>
        <h3 className="body-heading">What Our Client Say</h3>


        <div className="container-fluid pt-5">
          <div className="row">
            <div className="col-lg-6">
              <img src="/images/creatives/testimonials-image.webp"  className="img-fluid" alt="testimonial user"/>
            </div>
            <div className="col-lg-6">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              pagination={true}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              loop={true}
              autoplay={
                {
                  delay:2000,
                  disableOnInteraction:false
                }
              }
              modules={[Autoplay,Pagination]}
              observer ={true}
              observeParents={true}
              parallax={true}
              className="testimonials-list py-5"
              id="testimonial-cards-slider"
                  >
                    {
                      blogData.map((clientData,ind)=>{
                        return(<>
                          <SwiperSlide key={ind} >
                            <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                          </SwiperSlide>
                          </>)
                      })
                    }
              </Swiper>
              <div className="d-flex align-items-center justify-content-start mt-3">
                <a 
                  href="https://www.google.com/maps/place/HQ+Network+Solutions+Inc./@43.6598286,-79.6010181,17z/data=!4m8!3m7!1s0x882b3f2a275d1e1b:0xc04801cd06c594b8!8m2!3d43.6598286!4d-79.6010181!9m1!1b1!16s%2Fg%2F11dx9wxvdl?entry=ttu" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="genral-btn">read more</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        
       
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials
import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";
import SideBar from "../SideBar/SideBar";

const Header = ({slideState,removeNavLinks}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/services");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };
  const sideBarClick = () => {
    if(slideState)
    {
      slideState(!sideBar)
    }
    setSideBar((prev) => !prev);
  };

  return (
    <div className={`header mt-3`}>
      <div className="header-wrapper">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-lg-0 px-2 py-2">
          <SideBar
                  stateSide={sideBar}
                />
            <div className="nav-elements d-flex justify-content-between align-items-center px-lg-2">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/nav-logo.svg" alt="navigation logo" className="nav-logo" onClick={()=>{!removeNavLinks && navigate("/")}} style={{cursor:"pointer"}}/>
              
              {
                !removeNavLinks && 
                <nav>
                  <ul
                    className="nav-list d-lg-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                    id="js-menu"
                  >
                    <li>
                      <Link to={"/"} className="nav-links font-Open-Sans">
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about"} className="nav-links font-Open-Sans">
                        ABOUT
                      </Link>
                    </li>

                    <div className="navigation-dropdown" ref={dropdownRef}>
                    <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                        <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                            <Link to={"/services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#155A75":"white"}`}}>SERVICES</Link>
                            <img alt="arrow icon" src={`/images/icons/drop-icon-${changeArrowcolor ? "b":"w"}.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                        </div>
                        <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
                
                          <Link to={"/managed-it-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>managed it services</p>
                          </Link>
                          
                          <Link to={"/managed-it-security-and-compliance"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Managed IT Security & Compliance</p>
                          </Link>
                          <Link to={"/managed-cloud-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Managed Cloud Services</p>
                          </Link>
                          <Link to={"/it-strategic-project-managment"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>IT Strategic Project Managment</p>
                          </Link>
                          <Link to={"/it-audits"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>IT Audits</p>
                          </Link>
                          <Link to={"/co-managed-it-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>Co-Managed IT Services</p>
                          </Link>
                          <Link to={"/360-digital-marketing"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                            <p className="mb-0" style={{textTransform:"uppercase"}}>digital marketing</p>
                          </Link>
                        </div>
                      </li>
                      </div>



                    <li>
                      <Link to={"/blog"} className="nav-links font-Open-Sans">
                        
                        RESOURCES
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact"} className="nav-links font-Open-Sans">
                        CONTACT
                      </Link>
                    </li>
                  </ul>
                </nav>
              }

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/hamburger-menu.svg"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
                
              </label>
              <button className="d-lg-none d-block" style={{backgroundColor:"white",padding:"7px 12px 9px",borderRadius:"50%",border:"none",marginLeft:"10px"}} onClick={sideBarClick}>
                <img src="/images/icons/hamburger-menu.png" alt="menu icon" style={{width:"15px",height:"15px"}}/>
              </button>

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-3 extras-btns">
                <a href="tel:+16474794288"><button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}}  >
                <img src="/images/icons/phone-icon.svg" alt="phone icon" style={{marginRight:"7px"}}/>
                (647) 479 4288
                </button></a>
                <button style={{backgroundColor:"white",padding:"7px 12px 9px",borderRadius:"50%",border:"none"}} onClick={sideBarClick}>
                  <img src="/images/icons/hamburger-menu.png" alt="menu icon" style={{width:"15px",height:"15px"}}/>
                </button>
                
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-lg-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-lg-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/"} className="nav-links font-Open-Sans">
                  HOME
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="nav-links font-Open-Sans">
                  ABOUT
                </Link>
              </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className="nav-links font-Open-Sans" >SERVICES</a>
                      <img src='/images/icons/drop-icon-b.svg' alt="dropdown icon" className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } />
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/managed-it-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>managed itservices</p>
                    </Link>
                   
                    <Link to={"/managed-it-security-and-compliance"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Managed IT Security & Compliance</p>
                    </Link>
                    <Link to={"/managed-cloud-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Managed Cloud Services</p>
                    </Link>
                    <Link to={"/it-strategic-project-managment"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>IT Strategic Project Managment</p>
                    </Link>
                    <Link to={"/it-audits"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>IT Audits</p>
                    </Link>
                    <Link to={"/co-managed-it-services"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>Co-Managed IT Services</p>
                    </Link>
                    <Link to={"/360-digital-marketing"} className="nav-links font-Open-Sans" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0" style={{textTransform:"uppercase"}}>digital marketing</p>
                    </Link>
                  </div>
                </div>
                </li>
              <li>
                <Link to={"/blog"} className="nav-links font-Open-Sans">
                  RESOURCES
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className="nav-links font-Open-Sans">
                CONTACT
                </Link>
              </li>
            </ul>
            <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
            <a href="tel:+16474794288"><button className="genral-btn font-Open-Sans" style={{fontSize:"14px",fontWeight:"600"}}  >
                <img src="/images/icons/phone-icon.svg" style={{marginRight:"7px"}} alt="phone icon"/>
                (647) 479 4288
                </button>
                </a>
               
               
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 
import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function PrivacyPolicy() {
    return (
        <>
          <HeroBanner 
            subHeading=""
            Heading="Privacy Policy"
            nextLineSpanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creatives/about-hero-b.jpg"
            btn={false}
            btnRef="/"
            bgEffect={true}
            isCheveron={false}
          />

        <div className='container standard-padding-space'>
            <h3 className='body-heading mt-4 mb-3'>Privacy Policy for HQ Network Solutions</h3>
            <p className='body-paragraph'>Last Updated: February 2, 2024</p>
            <br/>
            <p className='body-paragraph'>
                Welcome to HQ Network Solutions ("HQNS," "we," "us," or "our"). At HQNS, we are committed to safeguarding your privacy and protecting your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal data when you use our website, <a href='/' style={{color:"#3AB6E7"}}>https://hqns.ca/</a>, and our related services. Please take a moment to review this Privacy Policy to understand how we handle your information.
            </p>


            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>1. Information We Collect:</h3>

            <p className='body-paragraph'>We may collect various types of information when you visit our website or engage with our services, including but not limited to:</p>
            <ul>
                <li><p className='body-paragraph'>Personal Information: Name, email address, phone number, and other contact details.</p></li>
                <li><p className='body-paragraph'><b>Log Data:</b> Information about your device and how you interact with our website, such as IP address, browser type, and pages visited.</p></li>
                <li><p className='body-paragraph'>Usage Information: Information about how you use our services.</p></li>
            </ul>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>2. How We Use Your Information:</h3>
            <p className='body-paragraph'>We use the collected information for the following purposes:</p>
            <ul>
                <li><p className='body-paragraph'>Providing and maintaining our services.</p></li>
                <li><p className='body-paragraph'>Communicating with you, including responding to your inquiries.</p></li>
                <li><p className='body-paragraph'>Improving our website and services.</p></li>
                <li><p className='body-paragraph'>Ensuring the security and compliance of our network solutions.</p></li>
                <li><p className='body-paragraph'>Conducting analytics to understand user behavior and preferences.</p></li>
            </ul>


            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>3. Sharing Your Information:</h3>
            <p className='body-paragraph'>We may share your personal information with:</p>
            <ul>
                <li><p className='body-paragraph'>Third-party service providers assisting us in delivering our services.</p></li>
                <li><p className='body-paragraph'>Legal authorities if required to comply with applicable laws or regulations.</p></li>
            </ul>
            <p className='body-paragraph'>We do not sell or rent your personal information to third parties.</p>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>4. Cookies and Similar Technologies:</h3>
            <p className='body-paragraph'>We use cookies and similar technologies to enhance your experience on our website and collect information about your browsing activities. You can manage your cookie preferences through your browser settings.</p>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>5. Security:</h3>
            <p className='body-paragraph'>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>6. Your Choices:</h3>
            <p className='body-paragraph'>You have the right to access, correct, or delete your personal information. If you have any questions or requests regarding your information, please contact us at privacy@hqns.ca.</p>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>7. Changes to this Privacy Policy:</h3>
            <p className='body-paragraph'>We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the updated policy on our website with the "Last Updated" date.</p>

            <h3 className='body-heading mt-4 mb-3' style={{fontSize:"25px",fontWeight:"600"}}>8. Contact Us:</h3>
            <p className='body-paragraph'>If you have any questions, concerns, or requests regarding this Privacy Policy or our practices, please contact us at privacy@hqns.ca</p>
            <p className='body-paragraph'>Thank you for choosing HQ Network Solutions!</p>

        </div>
    
          <Footer/>
        </>
      )
}

export default PrivacyPolicy
import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TextCard from "../component/TextCard/TextCard"
import { Helmet } from 'react-helmet'


const optimizedContentList = [
    {
        title:"1- Holistic Training Programs: ",
        content:"HQNS’s holistic training programs ensure that your team is not only proficient in addressing current challenges but is also equipped to tackle future technological advancements."
    },
    {
        title:"2- Proactive Cybersecurity Measures: ",
        content:"This proactive approach involves regular assessments, updates, and training to mitigate potential risks. HQNS becomes a partner in safeguarding your digital assets, providing a sense of security in an increasingly vulnerable online landscape."
    },
    {
        title:"3- Scalability: Meeting Evolving Business Needs ",
        content:"Whether your business is expanding or adapting to industry changes, HQNS ensures that your IT resources are not a limitation."
    },
]

const cardContent1 = [
    {
        title:"Expertise that exceeds all expectations",
        breifPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can ",
        actualPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can tackle any IT challenge that comes your way."
    },
    {
        title:"Lightning-fast response times to your IT needs",
        breifPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your ",
        actualPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your problems are addressed promptly, minimizing downtime and its associated costs."
    },
    {
        title:"Proactive IT strategies that are tailored to your specific requirements",
        breifPara:"We don’t just react to IT problems; we ",
        actualPara:"We don’t just react to IT problems; we anticipate them and develop strategies to prevent them. This proactive approach keeps your business running smoothly."
    },
    {
        title:"Unprecedented peace of mind for you and your business",
        breifPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that",
        actualPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that your IT infrastructure is in capable hands. We take care of the tech so you can focus on growing your business."
    },
]

const cardContent2 = [
    {
        title:"Reduced downtime thanks to proactive business continuity and disaster recovery services",
        actualPara:"We ensure your business can recover swiftly from any unexpected disruptions, minimizing downtime and its associated costs."
    },
    {
        title:"Mitigated vulnerabilities through the use of cybersecurity tools and constant monitoring",
        actualPara:"We prioritize your cybersecurity, making sure your data and systems are protected from potential threats."
    },
    {
        title:"Enhanced data protection using top-tier solutions at an affordable price",
        actualPara:"We provide enterprise-level data protection solutions at a cost-effective rate, ensuring your business’s critical data is safeguarded."
    },
]

const textDropDownList = [
    {
        title:"Strategic Labor Cost Reduction",
        content:"The expertise provided by HQNS ensures that every role is filled with the right skills, eliminating unnecessary expenses while maintaining a high level of support."
    },
    {
        title:"Equipment Optimization",
        content:"Instead of advocating for unnecessary hardware investments, HQNS assesses your existing infrastructure and recommends optimization strategies. "
    },
    {
        title:"A 23-Year Legacy",
        content:"The cost-effectiveness of HQNS’s Co-Managed IT Services is not a result of mere efficiency; it’s a product of a 23-year legacy."
    },
]

function CoManagedITServices() {
  return (
    <div className="home-page">
        <Helmet>
          <title>Co-Managed IT Services in Toronto | HQNS</title>
          <meta name='description' content="Partner with HQNS for Co-Managed IT Services in Toronto. Benefit from expertise, proactive strategies, and operational harmony. Amplify your advantages with HQNS's holistic approach." />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Co-Managed IT Services in Toronto"></meta>
        </Helmet>
      <HeroBanner 
      bgEffect={true}
        subHeading=""
        Heading={"Co-managed IT services"}
        postSubHeading="Partner with us and take your IT services to the next level"
        nextLineSpanHeading=""
        Paragraph={"Perfect blend of expertise, experience, and resources to tailor Co-Managed IT Services to your unique business needs. Let’s delve even deeper into the facets that make HQNS’s Co-Managed IT Services stand out, exploring the intricacies of each advantage and addressing potential concerns with a comprehensive approach."}
        bgImg="/images/creatives/co-managed-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>

    <TextCard
        heading='The distinct advantage of HQNS’S Co-managed IT services'
        content='When you choose HQNS, you gain access to a multitude of advantages including:'
        cardContent={cardContent1}
    />
</div>

<div className='left-gradient-upper'>
      <TextContent
         heading= "Operational Harmony"
         subHeading=""
         content={`The orchestration of Co-Managed IT Services in Toronto involves not only blurring the lines between in-house and external support but also fostering operational harmony. This collaboration is not a replacement but an enhancement of your existing team. HQNS integrates seamlessly, allowing your internal IT champions to concentrate on core business functions while leveraging external expertise strategically.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/co-managed-text-1.png"
         reviewContent={false}
      />
      
      <div className='right-gradient'> 

      <TextContent
         heading= "In-House and External Integration"
         subHeading=""
         content={`Ensuring that the integration of in-house and external teams is seamless is crucial for the success of Co-Managed IT Services. HQNS employs a meticulous onboarding process, facilitating effective communication channels and promoting a shared understanding of goals and objectives. This integration is not just about coexistence; it’s about creating a unified front focused on propelling your business forward.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/co-managed-text-2.png"
         reviewContent={false}
      />
      <TextContent
         heading= "Mitigating Collaboration Hiccups"
         subHeading=""
         content={`Collaboration hiccups often arise when there is a lack of clarity in roles and responsibilities. HQNS addresses this by establishing transparent communication channels, setting clear expectations, and defining the scope of collaboration. The result is a cohesive partnership where both teams work in tandem, ensuring that the collective expertise is harnessed to its full potential.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/co-managed-text-3.png"
         reviewContent={false}
      />

      <TextContent
         heading= "Advantages Amplified: Going Beyond the Basics"
         subHeading=""
         content={`The commitment to excellence doesn’t end with the initial collaboration. HQNS integrates continuous improvement initiatives into the partnership.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/co-managed-text-4.png"
         reviewContent={false}
         titleList={true}
         titleListContent={optimizedContentList}
      />
      </div>
</div>


<div className='right-gradient'> 
    <TextContent
         heading= "Dynamic Crisis Response"
         subHeading=""
         content={`In times of crisis, businesses need a swift and informed response. HQNS’s Co-Managed IT Services provide exactly that. The combination of external expertise and in-house familiarity creates a dynamic crisis response mechanism. HQNS ensures that your business maintains autonomy in operations, making critical decisions based on a comprehensive understanding of your unique challenges and objectives.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/co-managed-text-5.png"
         reviewContent={false}
      />
    <TextContent
         heading= "Cost-Effective IT Solutions: A Legacy of Excellence"
         subHeading=""
         content={`HQNS’s journey through the dynamic IT landscape has equipped it with insights and expertise that go beyond traditional tech support.`}
         btn={false}
         shiftRow={false}
         img="/images/creatives/co-managed-text-6.png"
         reviewContent={false}
         dropDown={true}
         dropDownList={textDropDownList}
      />
</div>
    

      <Footer/>
</div>
  )
}

export default CoManagedITServices
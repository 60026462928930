import "./DiscoverImageServices.css"
import { useNavigate } from "react-router-dom";



const serviceData = [
  {
    img: "/images/creatives/dis-services-1.png",
    title: "Co-Managed IT Services",
    anchor: "/co-managed-it-services",
    content:
      "Bridge the gap between your in-house IT team and the ever-evolving technological landscape. Leverage our expertise to enhance operational efficiencies..",
  },
  {
    img: "/images/creatives/dis-services-2.png",
    title: "IT Audits",
    anchor: "/it-audits",
    content:
      "Gain a clear and comprehensive view of your IT infrastructure’s health. Our meticulous audit process helps identify potential vulnerabilities...",
  },
  {
    img: "/images/creatives/dis-services-3.png",
    title: "IT Strategic Project Management",
    anchor: "/it-strategic-project-managment",
    content:
      "Navigate your IT projects to successful completion with our strategic project management service. We apply a forward-thinking approach",
  },

  {
    img: "/images/creatives/dis-services-4.png",
    title: "Managed Cloud Services",
    anchor: "/managed-cloud-services",
    content:
      "Embrace the future with cloud solutions that are scalable, secure, and tailored to meet your business needs. Our managed cloud services....",
  },
  {
    img: "/images/creatives/dis-services-5.png",
    title: "Managed IT Security & Compliance",
    anchor: "/managed-it-security-and-compliance",
    content:
      "Secure your business fortress with our comprehensive IT security and compliance services. We offer robust solutions that shield....",
  },
  {
    img: "/images/creatives/dis-services-6.png",
    title: "Managed IT Services",
    anchor: "/managed-it-services",
    content:
      "Experience holistic IT management that aligns with your business objectives. From proactive monitoring to continuous optimization...",
  },
  
];

function WhyChooseUs({
  heading="",
  subHeading="",
  content=""
}) {
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1 mb-3'>{paragraph}</p>);
  const navigate = useNavigate()
  return (
    <div className="discover-image-container standard-padding-space mb-4">
      <div className="container ">
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start my-5 text-content">
            <p className="sub-heading">{subHeading}</p>
            <h3 className="body-heading">{heading}</h3>
          </div>
          <div className="col-lg-6 text-center text-lg-start my-2 my-lg-5 text-conten">
            <p className="body-paragraph mt-1 mt-lg-5 mb-5 mb-lg-0 mx-3 mx-lg-0">{paragraphs}</p>
          </div>
          <div className="col-sm-12">
            <div className="container mt-4">
              <div className="row gy-4">
                {
                  serviceData.map((ele)=>{
                    return(
                      <div className=" col-md-4 col-sm-6 col-12">
                        <div className="discover-card-box mx-auto text-center h-100">
                              <img src={ele.img} className="mb-2" alt="img"/>
                          <h5 className="sub-heading font-noto" style={{fontSize:"1.4rem"}}>{ele.title}</h5>
                          <p className="body-paragraph mb-0">{ele.content}</p>
                          <a className="read-more-anchor body-paragraph" onClick={()=>{navigate(`${ele.anchor}`)}}>Learn More<img  src="/images/icons/carousel-btn-icon.png" alt="carousal slider button" className="btn-icon mx-2"/></a>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;

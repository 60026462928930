import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import Sponsor from '../component/Sponsor/Sponsor'

function Contact() {
    return (
      <div className="home-pag">
        <Helmet>
          <title>Contact HQNS for Expert Solutions</title>
          <meta name='description' content='Connect with HQNS for tailored solutions to your needs. Get in touch today for professional assistance and personalized services.' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Contact Us"></meta>
        </Helmet>
        <HeroBanner 
          subHeading=""
          Heading="Ready to Optimize Your Business Operations? "
          spanHeading='Let’s Connect!'
          nextLineSpanHeading=""
          Paragraph="Contact HQNS today to discuss your IT needs and discover how we can help you achieve your business goals!"
          btnText=""
          bgImg="/images/creatives/contact-hero-banner.jpg"
          btn={false}
          btnRef="/"
          bgEffect={true}
          socialLinks={true}
          serviceForm={true}
          isCheveron={false}
        />

  
        <Footer/>
  </div>
    )
  }
  
  export default Contact
import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TextCard from "../component/TextCard/TextCard"
import { Helmet } from 'react-helmet'


const optimizedContentList = [
    {
        title:"1- Global Security Operation Centers (SOCs):",
        content:"Our SOC teams work collaboratively to analyze and mitigate risks, providing you with a proactive defense mechanism against evolving cyber threats."
    },
    {
        title:"2- Simplifying Compliance Challenges:",
        content:"HQNS simplifies this process by offering steadfast support and expertise. We take the burden off your shoulders, allowing your business to focus on its core functions with the confidence of regulatory adherence."
    },
    {
        title:"3- Bespoke Network Security Solutions:",
        content:"HQNS takes pride in its bespoke approach to network security solutions in Toronto. Our services are adaptable to the diverse needs of businesses in the Toronto area. "
    },
]

const cardContent1 = [
    {
        title:"Expertise that exceeds all expectations",
        breifPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can ",
        actualPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can tackle any IT challenge that comes your way."
    },
    {
        title:"Lightning-fast response times to your IT needs",
        breifPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your ",
        actualPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your problems are addressed promptly, minimizing downtime and its associated costs."
    },
    {
        title:"Proactive IT strategies that are tailored to your specific requirements",
        breifPara:"We don’t just react to IT problems; we ",
        actualPara:"We don’t just react to IT problems; we anticipate them and develop strategies to prevent them. This proactive approach keeps your business running smoothly."
    },
    {
        title:"Unprecedented peace of mind for you and your business",
        breifPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that",
        actualPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that your IT infrastructure is in capable hands. We take care of the tech so you can focus on growing your business."
    },
]


const textDropDownList = [
    {
        title:"Network Protection",
        content:"We bring elite protection, impeccable compliance solutions, and sustained productivity to the forefront. "
    },
    {
        title:"Dynamic Cyber security",
        content:"From Managed Compliance Services to Digital Risk & Threat Monitoring, we do it all."
    },
    {
        title:"Empowering your organization",
        content:"Our solutions proactively monitor, mitigate, and respond to digital risks and emerging threats in real-time."
    },
]

function ManagedItComplience() {
  return (
    <div className="home-page">
        <Helmet>
          <title>Managed IT Security & Compliance Services in Toronto | HQNS</title>
          <meta name='description' content='Discover the peace of mind our Managed IT Security & Compliance services can offer! HQNS provides expert IT solutions tailored to your business needs, ensuring regulatory adherence and proactive defense against cyber threats.' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Managed IT Security And Compliance Services in Toronto"></meta>
        </Helmet>
      <HeroBanner 
      bgEffect={true}
        subHeading=""
        Heading={"Managed IT Security & Compliance"}
        postSubHeading="Discover the peace of mind our Managed IT Security & Compliance services can offer!"
        nextLineSpanHeading=""
        Paragraph={"In the ever-evolving landscape of cyber threats and complex compliance standards, HQNS emerges as an unwavering partner, delivering an expansive suite of Managed IT Security & Compliance services in Toronto. This holistic approach not only strengthens your digital fortress against potential cyber adversaries but also effortlessly surpasses stringent regulatory benchmarks, paving the way for a secure and resilient digital future."}
        bgImg="/images/creatives/misc-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>
      {/* <Sponsor/> */}

    <TextCard
        heading='The distinct advantage of HQNS’S Managed IT services'
        content='When you choose HQNS, you gain access to a multitude of advantages including:'
        cardContent={cardContent1}
    />
</div>

<div className='left-gradient-upper'>
      <TextContent
         heading= "Managed IT compliance Solutions for Businesses in Toronto"
         subHeading=""
         content={`Experience a tailored approach designed to meet the unique needs of your business. With strategically positioned Security Operation Centers globally, HQNS facilitates not just collaboration but exceptional collaboration. Our innovative Incident Management Platform provides unparalleled visibility, ensuring a proactive stance against potential threats and offering a robust defense mechanism for your invaluable digital assets.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/misc-text-1.png"
         reviewContent={false}
      />
      
      <div className='right-gradient'> 

      <TextContent
         heading= "Simplifying IT Compliance Challenges in Toronto"
         subHeading=""
         content={`Navigating the intricate landscape of Managed IT compliance in Toronto, from frameworks like CMMC to industry-specific regulations like HIPAA, can be overwhelming. HQNS takes the lead in simplifying these complexities, offering steadfast support. Entrust us with the compliance landscape, enabling your organization to channel its energies towards core business functions with the confidence of regulatory adherence.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/misc-text-2.png"
         reviewContent={false}
      />

      <TextContent
         heading= "A Multifaceted IT Ecosystem by HQNS in Toronto"
         subHeading=""
         content={`Explore a comprehensive suite of IT services catering to the diverse needs of modern businesses. From Onsite IT Support and Remote Technical Support to IT Consulting, Troubleshooting and Repair, Vendor Management, Network Monitoring, Security and Training, Data Backups, Hardware and Software Procurement, Cloud Services, and Special Projects, HQNS encompasses an all-encompassing IT support ecosystem.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/misc-text-3.png"
         reviewContent={false}
      />

      <TextContent
         heading= "Innovative Incident Management Platform"
         subHeading=""
         content={`From navigating frameworks like CMMC to industry-specific regulations like HIPAA, our experts handle the compliance landscape. This allows businesses to focus on core functions with the confidence of regulatory adherence.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/misc-text-4.png"
         reviewContent={false}
         titleList={true}
         titleListContent={optimizedContentList}
      />
      </div>
</div>


<div className='right-gradient'> 
    <TextContent
         heading= "Crafted Network Security Solutions Toronto for Your Business"
         subHeading=""
         content={`Experience a tailored approach designed to meet the unique needs of your business. With strategically positioned Security Operation Centers globally, HQNS facilitates not just collaboration but exceptional collaboration. Our innovative Incident Management Platform provides unparalleled visibility, ensuring a proactive stance against potential threats and offering a robust defense mechanism for your invaluable digital assets.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/misc-text-5.png"
         reviewContent={false}
      />
    <TextContent
         heading= "Future Resilience Empowered by HQNS"
         subHeading=""
         content={`Investing in the future resilience of your business goes beyond necessity—it’s a strategic imperative. HQNS stands as a reliable ally, providing more than conventional cybersecurity services. `}
         btn={false}
         shiftRow={false}
         img="/images/creatives/misc-text-6.png"
         reviewContent={false}
         dropDown={true}
         dropDownList={textDropDownList}
      />
</div>
    

      <Footer/>
</div>
  )
}

export default ManagedItComplience
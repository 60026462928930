import React,{useState} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import PopularBlogs from "../component/PopularBlogs/PopularBlogs"
import { Helmet } from 'react-helmet'


const catagory = [
    {
        name:"All",
        num:0
    },
    {
        name:"Blog",
        num:2
    },
    {
        name:"Business",
        num:3
    },
    {
        name:"Mobile",
        num:4
    },
    {
        name:"Product",
        num:5
    },
    {
        name:"Machine Learning",
        num:6
    }
]

function Blog() {
    const [selCatagory,setSelCatagory] = useState({
        name:"All",
        num:0
    })



  return (
    <>
    <div className="home-pag">
        <Helmet>
          <title>Discover the Latest Insights on Home Renovation Trends | HQNS Blog</title>
          <meta name='description' content='Stay updated with the latest trends in home renovation. Find expert advice, tips, and inspiration for your next project on the HQNS blog. Dive into insightful content today!' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Blogs"></meta>
        </Helmet>
       <HeroBanner 
         subHeading=""
         Heading="We help businesses"
         nextLineSpanHeading=""
         Paragraph="Empower people through technology and innovative solutions"
         btnText=""
         bgImg="/images/creatives/digital-hero-.jpg"
         btn={false}
         btnRef="/"
         bgEffect={true}
         isCheveron={false}
       />
       
<div className='left-gradient-upper'>
        <div className='blog-Catagory'>
            <div className='container'>
                <div className='main-container' style={{borderRadius:"50px"}}>
                    <div 
                        className='row gy-2 main-catagory-container mx-3 mx-lg-0' 
                    >
                        {
                            catagory.map((ele)=>{
                                return(
                                <div 
                                    className={`col-xxl-2 col-lg-4  catagory-container ${ele.num == selCatagory.num ? "active" : ""} `}
                                    onClick={()=>{setSelCatagory(ele)}}    
                                >
                                    <p className='body-heading mb-0' >{ele.name}</p>
                                </div>
                                )
                            })
                        }
                        
                    </div>

                </div>

            </div>

        </div>
       <PopularBlogs
            selectedCatagory={selCatagory.num}
       />
 
   </div>
       <Footer/>
 </div>
   </>
  )
}

export default Blog
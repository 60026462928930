import React from 'react'
import "./DropDownContent.css"
import DropDownBox from "./DropDownBox"

const dropdownData = [
    {
        title:"Public Cloud Integration",
        content:"Migrate your workloads to the cloud and leverage its scalability, agility, and cost-efficiency.Enable seamless access to data and applications from anywhere, at any time, on any device."
    },
    {
        title:"24/7 Monitoring",
        content:"Our vigilant monitoring ensures your systems are always operational, minimizing downtime and maximizing productivity. Stay informed with comprehensive dashboards and reports that help you identify potential problems and optimize performance."
    },
    {
        title:"Data Backup Solutions",
        content:"Ensure business continuity in the face of unforeseen circumstances with reliable disaster recovery plans and procedures. Meet stringent data privacy regulations with secure and compliant data storage solutions."
    },
    {
        title:"IT Consultation",
        content:"Strategic roadmap development: We collaborate with you to define your technology goals and develop a roadmap to achieve them. Our experienced IT professionals help you choose the right technologies and solutions for your unique needs."
    },
]



function DropDownContent({
    shiftRow=false,
    img,
    mobileTextCenter=true
  }) {
    return (
      <div className='drop-down-content-section standard-padding-space'>
        <div className='container'>
          <div className={`row d-flex ${shiftRow && "flex-row-reverse"} align-items-center gy-2`} >
            <div className={`col-lg-6 p-2 px-3 px-lg-2 d-flex align-items-center justify-content-center ${shiftRow ? "justify-content-lg-end" : "justify-content-lg-start"}`}>
              <img src={img} className='img-fluid' alt='side '/>
            </div>
            <div className='col-lg-6'>
              <div className={`text-content ${mobileTextCenter && "text-center "} `}>
                
              {
                    dropdownData.map((ele)=>{
                        return(
                            <div className='col-12'>
                                <DropDownBox title={ele.title} content={ele.content} />
                            </div>
                        )
                    })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default DropDownContent
import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import TextCard from "../component/TextCard/TextCard"
import Contactform from '../component/Contact Form/Contactform'
import LandingFooter from '../component/LandingFooter/LandingFooter'

const cardContent = [
    {
        title:"Expertise that goes above and beyond",
        breifPara:"Our team's deep knowledge and experience ensure top-notch solutions",
        actualPara:"Our team's deep knowledge and experience ensure top-notch solutions customized to your needs."
    },
    {
        title:"Rapid response times for your IT issues ",
        breifPara:"Count on us for lightning-fast support whenever you encounter ",
        actualPara:"Count on us for lightning-fast support whenever you encounter IT challenges."
    },
    {
        title:"Customized proactive IT strategies just for you",
        breifPara:"We design proactive strategies specific to your business, keeping ",
        actualPara:"We design proactive strategies specific to your business, keeping you ahead of the curve."
    },
    {
        title:"Unmatched peace of mind for you and your business",
        breifPara:"Relax knowing that your IT needs are in expert hands, allowing ",
        actualPara:"Relax knowing that your IT needs are in expert hands, allowing you to focus on what matters most—your business."
    },
]

function LandingPage() {
  return (
    <>
        <HeroBanner 
          subHeading=""
          Heading="Empowering Businesses with Innovative IT Solutions "
          spanHeading=''
          nextLineSpanHeading=""
          Paragraph="HQ Network Solutions - Where Passion and Professionalism Come Together in Tech Support"
          btnText="Let's Connect"
          bgImg="/images/creatives/landing-hero-banner.jpg"
          btn={true}
          btnRef="/it-services"
          bgEffect={true}
          socialLinks={false}
          serviceForm={true}
          isCheveron={false}
          removeNavLinks={true}
        />

    
        <Sponsor/>

        <div className='container standard-padding-space'>
            <div className='text-center'>
                <p className='sub-heading'>IT SERVICES</p>
                <h3 className='body-heading'>The Services We Offer You</h3>
                <p className='body-paragraph my-3 w-75 m-auto'>In a world constantly shaped by technological advancements, having a trusted IT partner can be your ticket to staying ahead. At HQ Network Solutions, we bring you over four decades of expertise in providing digital solutions.</p>

            </div>

        </div>

        <div className='left-gradient'>
        <TextContent
           heading= "Managed IT Security & Compliance"
           subHeading=""
           content="In today's ever-changing world of online threats and complicated rules, HQ Network Solutions is here to help. We offer a wide range of Managed IT Security & Compliance services in Toronto, making sure your digital defenses are strong against cyber threats and follow all the rules."
           btn={true}
           btnText="Let's Connect"
           btnRef='/it-services'
           btnScroll={true}
           shiftRow={true}
           img="/images/creatives/landing-text-1.png"
           reviewContent={false}
        />
        <TextContent
           heading= "Cloud Services"
           subHeading=""
           content="In the fast-moving business world of 2024, using Managed Cloud Services in Toronto isn't just important—it's necessary for growth. HQ Network Solutions is your guide, offering top Managed Cloud Services in Toronto that will transform your business in Canada's busy hub."
           btn={true}
           btnText="Let's Connect"
           btnRef='/it-services'
           shiftRow={false}
           btnScroll={true}
           img="/images/creatives/landing-text-2.png"
           reviewContent={false}
        />
        </div>

        <TextContent
           heading= "IT Strategic Project Management"
           subHeading=""
           content="In the tech world, staying ahead and managing IT projects well is important. HQ Network Solutions leads the way in IT Strategic Project Management in Toronto. We turn IT challenges into successes, whether it's improving software or boosting security, with a plan that fits your needs."
           btn={true}
           btnText="Let's Connect"
           btnRef='/it-services'
           shiftRow={true}
           img="/images/creatives/landing-text-3.png"
           reviewContent={false}
           btnScroll={true}
        />
        <TextContent
           heading= "Co-Managed IT Services"
           subHeading=""
           content="At HQ Network Solutions, we offer a perfect mix of skills, experience, and resources for Co-Managed IT Services customized to your business. Let's explore why HQ Network Solutions' Co-Managed IT Services are special, addressing concerns fully and looking closely at each advantage we offer"
           btn={true}
           btnText="Let's Connect"
           btnRef='/it-services'
           shiftRow={false}
           img="/images/creatives/landing-text-4.png"
           reviewContent={false}
           btnScroll={true}
        />

        <TextCard
            heading="The Benefits of HQ Network Solutions' Managed IT Services"
            content='Choosing HQ Network Solutions means unlocking a host of benefits, such as:'
            cardContent={cardContent}
        />

        <Contactform/>

        <LandingFooter/>
    </>
  )
}

export default LandingPage
import React from "react";
import styles from "./IdvBlogSec.module.css";
import PopularBlogCard from "./PopularBlogCard";
import "./Card.css"
import { useNavigate } from "react-router-dom";

const IdvBlogSec = ({ text, blogsData,recentBlogs }) => {

  const navigate = useNavigate()

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  return (
    <div className={`${styles.main} main idvBlogMain`}>
      
      <div className="container">
        <div className="row gy-4">
            <div className="col-lg-6">
              <div className={`para_main pt-4 pb-5 ${styles.para_main}`} style={{color:"white"}} dangerouslySetInnerHTML={{ __html: text }}></div>
                {blogsData.map((item) => (
                  <>
                    <h2 className="h2_main pb-2 body-heading" style={{color:"#39B4E5",textTransform:"uppercase"}}>{item.heading}</h2>
                    <div className={`para_main pb-5 body-paragraph ${styles.para_main}`} dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                  </>
                ))}
              
            </div>
            <div className="col-lg-6">
              <div className='popular-card-container p-3'>
                    {
                        recentBlogs.map((ele)=>{
                            return(
                                <PopularBlogCard
                                    img={ele.logo}
                                    title={ele.blog_description}
                                    anchor={ele.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                />
                            )
                        })
                    }
                    </div>
            </div>
        </div>
        
       
         
        </div>
      </div>
  );
};

export default IdvBlogSec;

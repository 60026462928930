import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TextCard from "../component/TextCard/TextCard"
import { Helmet } from 'react-helmet'


const optimizedContentList = [
    {
        title:"1- Intuitive Platform Excellence: ",
        content:"Empower your growth trajectory with an easy-to-use platform offering unlimited sites, powerful add-ons, flexible workflows, and seamless vertical scaling."
    },
    {
        title:"2- Expert Technical Support: ",
        content:"Our in-house experts, akin to guardians of progress, provide automatic updates and unwavering support, allowing you to concentrate on propelling your business forward."
    },
    {
        title:"3- Optimized Stack for Peak Performance: ",
        content:"HQNS deploys servers with a pre-installed stack, meticulously including Nginx, Apache, and Memcached, ensuring not just optimal performance but an efficiency that echoes the heartbeat of your operations."
    },
]

const cardContent1 = [
    {
        title:"Expertise that exceeds all expectations",
        breifPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can ",
        actualPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can tackle any IT challenge that comes your way."
    },
    {
        title:"Lightning-fast response times to your IT needs",
        breifPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your ",
        actualPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your problems are addressed promptly, minimizing downtime and its associated costs."
    },
    {
        title:"Proactive IT strategies that are tailored to your specific requirements",
        breifPara:"We don’t just react to IT problems; we ",
        actualPara:"We don’t just react to IT problems; we anticipate them and develop strategies to prevent them. This proactive approach keeps your business running smoothly."
    },
    {
        title:"Unprecedented peace of mind for you and your business",
        breifPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that",
        actualPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that your IT infrastructure is in capable hands. We take care of the tech so you can focus on growing your business."
    },
]

const cardContent2 = [
    {
        title:"Reduced downtime thanks to proactive business continuity and disaster recovery services",
        actualPara:"We ensure your business can recover swiftly from any unexpected disruptions, minimizing downtime and its associated costs."
    },
    {
        title:"Mitigated vulnerabilities through the use of cybersecurity tools and constant monitoring",
        actualPara:"We prioritize your cybersecurity, making sure your data and systems are protected from potential threats."
    },
    {
        title:"Enhanced data protection using top-tier solutions at an affordable price",
        actualPara:"We provide enterprise-level data protection solutions at a cost-effective rate, ensuring your business’s critical data is safeguarded."
    },
]

const textDropDownList = [
    {
        title:"Cloud Integration Services",
        content:"Accelerate your operations with HQNS, seamlessly integrating current business processes, databases, and network resources with cloud solutions, enhancing resilience, reliability, and cost efficiency."
    },
    {
        title:"Cloud Migration Services",
        content:"Trust in a reliable cloud migration strategy, artfully developed and executed by HQNS, seamlessly moving your databases, servers, and business software to a secure cloud platform."
    },
    {
        title:"Cloud App Development",
        content:"Gain access to robust cloud applications, carefully crafted with the latest cloud technologies and forward-looking development approaches."
    },
]

function ManagedCloudServices() {
  return (
    <div className="home-page">
        <Helmet>
          <title>Managed Cloud Services Toronto | HQNS</title>
          <meta name='description' content='Revolutionize your business landscape with cutting-edge managed cloud services in Toronto. HQNS offers expert support, proactive strategies, and unparalleled peace of mind. Contact us now!' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="Managed Cloud Services in Toronto"></meta>
        </Helmet>
      <HeroBanner 
      bgEffect={true}
        subHeading=""
        Heading={"Managed Cloud Services"}
        postSubHeading="Revolutionize Your Business Landscape with Cutting-Edge Managed Cloud Services in Toronto"
        nextLineSpanHeading=""
        Paragraph={"As we traverse the intricate pathways of the business landscape in 2023, the adoption of Managed Cloud Services in Toronto emerges as not just a necessity but a catalyst for transformative growth. HQNS, your steadfast ally in the realm of technology, presents itself as the gateway to unparalleled Managed Cloud Services in Toronto, poised to redefine the trajectory of your business within the bustling heart of Canada. "}
        bgImg="/images/creatives/mcs-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>

    <TextCard
        heading='The distinct advantage of HQNS’S Managed Cloud Services'
        content='When you choose HQNS, you gain access to a multitude of advantages including:'
        cardContent={cardContent1}
    />
</div>

<div className='left-gradient-upper'>
      <TextContent
         heading= "Navigating the Uncharted Territory with Expertly Crafted Managed Cloud Services"
         subHeading=""
         content={`Bid farewell to the conventional era of on-site servers, for HQNS is here to guide your business into the future with cloud platforms like AWS, Azure, and Google Cloud. Within the dynamic and competitive Toronto business ecosystem, HQNS seamlessly integrates the expansive capabilities of the cloud with expert management services, fostering a unique synergy that not only adapts but propels your office operations to unprecedented heights.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/mcs-text-1.png"
         reviewContent={false}
      />
      
      <div className='right-gradient'> 

      <TextContent
         heading= "Distinctive Managed Cloud Services in Toronto"
         subHeading=""
         content={`Embark on a journey of discovery as HQNS’s Managed Cloud Services unfold a myriad of benefits meticulously tailored for the unique Toronto business landscape. Experience not only cost savings, future-proofed technology, custom and integrated services, robust infrastructure, predictable monthly costs, but also centralized services designed to harmonize seamlessly with the distinctive needs of businesses thriving in Toronto.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/mcs-text-2.png"
         reviewContent={false}
      />

        <TextContent
         heading= "Ensuring security in Managed Cloud Services in Toronto"
         subHeading=""
         content={`HQNS, akin to a vigilant sentinel, prioritizes security through measures such as SSL/TLS, end-to-end encryption, API credentials, and more, ensuring a robust shield against unauthorized access and potential threats. HQNS unfolds a comprehensive playbook of encrypted cloud document management solutions, empowering Toronto-based companies with intelligent business workflows and enhanced data security.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/mcs-text-3.png"
         reviewContent={false}
      />
      <TextContent
         heading= "Defining HQNS Managed Cloud Services Excellence in Toronto"
         subHeading=""
         content={`Our unwavering commitment to delivering superior Managed Cloud Services in Toronto is the compass guiding your business through the ever-evolving technological landscape.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/mcs-text-4.png"
         reviewContent={false}
         titleList={true}
         titleListContent={optimizedContentList}
      />
      </div>
</div>

<div className='right-gradient'> 
    <TextContent
         heading= "Redefining Operational Efficiency"
         subHeading=""
         content={`HQNS distinguishes itself with reduced operational costs, super-speed scalability, expert-level security, maximum uptime, and a dedicated team of Cloud specialists finely attuned to the intricacies of the Toronto business environment.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/mcs-text-5.png"
         reviewContent={false}
      />
    <TextContent
         heading= "Comprehensive Managed Cloud Services in Toronto"
         subHeading=""
         content={`Embark on a journey through the meticulously crafted offerings, designed to elevate your business within the vibrant Toronto business landscape:`}
         btn={false}
         shiftRow={true}
         img="/images/creatives/mcs-text-6.png"
         reviewContent={false}
         dropDown={true}
         dropDownList={textDropDownList}
      />
</div>
    

      <Footer/>
</div>
  )
}

export default ManagedCloudServices
import "./DiscoverServices.css";
import { useNavigate } from "react-router-dom";







const serviceData = [
  {
    img: "/images/icons/discover-services-icon-1.svg",
    title: "Managed IT Services",
    anchor: "/managed-it-services",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },
  {
    img: "/images/icons/discover-services-icon-2.svg",
    title: "Managed IT Security & Compliance",
    anchor: "/managed-it-security-and-compliance",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },
  {
    img: "/images/icons/discover-services-icon-3.svg",
    title: "Managed Cloud Services",
    anchor: "/managed-cloud-services",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },

  {
    img: "/images/icons/discover-services-icon-4.svg",
    title: "IT Strategic Project Managment",
    anchor: "/it-strategic-project-managment",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },
  {
    img: "/images/icons/discover-services-icon-5.svg",
    title: "IT Audits",
    anchor: "/it-audits",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },
  {
    img: "/images/icons/discover-services-icon-6.svg",
    title: "Co-Managed IT Services",
    anchor: "/co-managed-it-services",
    content:
      "In a world continuously shaped and reshaped by technological advancements, having a reliable",
  },
  
];

function WhyChooseUs({
  heading="",
  subHeading="",
  content=""
}) {
  const navigate = useNavigate()
  return (
    <div className="discover-container standard-padding-space mb-4">
      <div className="container ">
        <div className="row">
          <div className="col-sm-12 text-center my-5 text-content">
            <p className="sub-heading">{subHeading}</p>
            <h3 className="body-heading">{heading}</h3>
            <p className="body-paragraph mt-3">{content}</p>
          </div>
          <div className="col-sm-12">
            <div className="container mt-4">
              <div className="row gy-4">
                {
                  serviceData.map((ele)=>{
                    return(
                      <div className=" col-md-4 col-sm-6 col-12">
                        <div className="discover-card-box mx-auto text-center h-100">
                          <div className="card-icon">
                              <img src={ele.img} alt="service card icon"/>
                          </div>
                          <h5 className="card-title font-noto">{ele.title}</h5>
                          <p className="body-paragraph mb-0">{ele.content}</p>
                          <a className="read-more-anchor body-paragraph" onClick={()=>{navigate(`${ele.anchor}`)}}>Learn More<img src="/images/icons/carousel-btn-icon.png" alt="carousal slider button" className="btn-icon mx-2"/></a>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;

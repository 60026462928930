import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TextCard from "../component/TextCard/TextCard"
import { Helmet } from 'react-helmet'


const optimizedContentList = [
    {
        title:"1- Using a Gantt Chart:",
        content:"HQNS employs Gantt charts to add tasks to a timeline, connect dependencies, set milestones, and identify critical paths. This visual planning tool provides project managers with a comprehensive overview."
    },
    {
        title:"2- Using a Kanban Board: ",
        content:"For workflow control during project execution, HQNS uses a Kanban board. This flexible structure allows for workflow customization, sprint management, and seamless integration with the Gantt chart."
    },
    {
        title:"3- Managing Workload: ",
        content:"Smart workload management is essential for IT projects. HQNS’s software includes a Workloads page, indicating team members’ availability and workload. This feature facilitates task reassignment and avoids execution delays."
    },
]

const cardContent1 = [
    {
        title:"Expertise that exceeds all expectations",
        breifPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can ",
        actualPara:"Our team of IT experts is here to provide you with unparalleled knowledge and support. We’ve seen it all and can tackle any IT challenge that comes your way."
    },
    {
        title:"Lightning-fast response times to your IT needs",
        breifPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your ",
        actualPara:"When you encounter an IT issue, time is of the essence. HQNS ensures that your problems are addressed promptly, minimizing downtime and its associated costs."
    },
    {
        title:"Proactive IT strategies that are tailored to your specific requirements",
        breifPara:"We don’t just react to IT problems; we ",
        actualPara:"We don’t just react to IT problems; we anticipate them and develop strategies to prevent them. This proactive approach keeps your business running smoothly."
    },
    {
        title:"Unprecedented peace of mind for you and your business",
        breifPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that",
        actualPara:"With HQNS as your Managed IT Services provider, you can rest easy knowing that your IT infrastructure is in capable hands. We take care of the tech so you can focus on growing your business."
    },
]

const cardContent2 = [
    {
        title:"Reduced downtime thanks to proactive business continuity and disaster recovery services",
        actualPara:"We ensure your business can recover swiftly from any unexpected disruptions, minimizing downtime and its associated costs."
    },
    {
        title:"Mitigated vulnerabilities through the use of cybersecurity tools and constant monitoring",
        actualPara:"We prioritize your cybersecurity, making sure your data and systems are protected from potential threats."
    },
    {
        title:"Enhanced data protection using top-tier solutions at an affordable price",
        actualPara:"We provide enterprise-level data protection solutions at a cost-effective rate, ensuring your business’s critical data is safeguarded."
    },
]

const textDropDownList = [
    {
        title:"Developing plans to achieve project objectives",
        content:"Tracking project budgets and schedules, producing status reports. Managing resources, including the group and tools."
    },
    {
        title:"Assigning tasks to team members",
        content:"Devising plans for timely and budget-friendly project completion. Monitoring performance using IT project management software."
    },
    {
        title:"Effective communication skills and expertise",
        content:"Assessing project risks and developing risk management strategies. Hosting regular meetings for the team and stakeholders."
    },
]

function ITStrategicProjectManagment() {
  return (
    <div className="home-page">
        <Helmet>
          <title>IT Strategic Project Management in Toronto | HQNS</title>
          <meta name='description' content='Unlock the power of IT Strategic Project Management in Toronto with HQNS. Expertise, lightning-fast response, proactive strategies, and peace of mind for your business. Connect with us now!' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="IT Strategic Project Management Services in Toronto"></meta>
        </Helmet>
      <HeroBanner 
      bgEffect={true}
        subHeading=""
        Heading={"IT strategic project management"}
        postSubHeading="Unleashing the Power of IT Strategic Project Management in Toronto with HQNS"
        nextLineSpanHeading=""
        Paragraph={"In the dynamic realm of technology, businesses face the challenge of staying ahead, adopting innovations, and efficiently managing IT projects. The key to overcoming these challenges lies in effective IT Strategic Project Management in Toronto. HQNS, a trailblazer in this domain, transforms IT challenges into triumphs. From software enhancements to fortifying security measures, HQNS offers a strategic plan tailored to your unique needs."}
        bgImg="/images/creatives/ispm-hero-bg.jpg"
        btn={false}
      />

<div className='left-gradient'>
      {/* <Sponsor/> */}

    <TextCard
        heading='The distinct advantage of HQNS’S IT Strategic Project Management Services'
        content='When you choose HQNS, you gain access to a multitude of advantages including:'
        cardContent={cardContent1}
    />
</div>

<div className='left-gradient-upper'>
      <TextContent
         heading= "Embracing IT Strategic Project Management in Toronto"
         subHeading=""
         content={`HQNS provides a comprehensive roadmap from project initiation to successful closure. Beyond mere technicalities, the focus is on making technology work for you. Whether your preference is Agile or Waterfall, HQNS guides you in aligning your IT strategy seamlessly with your business goals. The ease of accessing HQNS’s IT strategic project management services is just a click away.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/ispm-text-1.png"
         reviewContent={false}
      />
      
      <div className='right-gradient'> 

      <TextContent
         heading= "HQNS’s Offering in IT Strategic Project Management in Toronto"
         subHeading=""
         content={`Managing new technology projects from inception to completion can be daunting, especially when resources or specific skills are lacking. HQNS’s IT strategic project management services bridge this gap by aligning business and technology strategies. The company navigates the intricacies of project management, leveraging highly skilled personnel, robust global connections, and a track record of success.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/ispm-text-2.png"
         reviewContent={false}
      />
      <TextContent
         heading= "Skills and Qualifications of HQNS’s IT Strategic Project Management Team"
         subHeading=""
         content={`The team at HQNS possesses a spectrum of skills and qualifications crucial for effective IT strategic project management. Responsibilities include planning, execution, monitoring, reporting, resource management, and ensuring project success within predefined parameters of schedule, budget, and quality. Key skills encompass technical management, understanding of technology trends, data analysis, communication, problem-solving, data center management, strategic planning, and quality management.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/ispm-text-3.png"
         reviewContent={false}
      />

      <TextContent
         heading= "Problem-Solving Approach at HQNS"
         subHeading=""
         content={`Navigating IT project management challenges requires a systematic approach. HQNS employs a problem-solving methodology, utilizing IT project management software to streamline various stages of project management.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={false}
         img="/images/creatives/ispm-text-4.png"
         reviewContent={false}
         titleList={true}
         titleListContent={optimizedContentList}
      />
      </div>
</div>


<div className='right-gradient'> 
    <TextContent
         heading= "IT Strategic Project Management Methodologies"
         subHeading=""
         content={`While most IT projects follow a structured waterfall methodology, HQNS recognizes the importance of flexibility. Agile frameworks can mitigate risks when introducing new functionalities, and DevOps aligns well with organizational culture. Rapid Application Development (RAD) offers a quality process with minimal capital investment.`}
         btn={true}
         btnText="Let's Connect"
         btnRef='/contact'
         shiftRow={true}
         img="/images/creatives/ispm-text-5.png"
         reviewContent={false}
      />
    <TextContent
         heading= "Propelling Your Business into the IT Stratosphere"
         subHeading=""
         content={`The digital future awaits, and with HQNS, you’re not just part of it; you lead it. As technology evolves, HQNS ensures you thrive in the IT stratosphere. The game plan is simple: connect with HQNS, strategize, and make IT magic together. Seize the opportunity to propel your business seamlessly into the digital age.`}
         btn={false}
         shiftRow={false}
         img="/images/creatives/ispm-text-6.png"
         reviewContent={false}
         dropDown={true}
         dropDownList={textDropDownList}
      />
</div>
    

      <Footer/>
</div>
  )
}

export default ITStrategicProjectManagment
import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import MarketingCard from '../component/MarketingCard/MarketingCard'
import { Helmet } from 'react-helmet'

function DigitalMarketing() {
  return (
    <>
     <div className="home-pag">
        <Helmet>
          <title>HQNS - Empowering Businesses with IT Solutions for 40+ Years</title>
          <meta name='description' content='HQNS offers cutting-edge IT solutions with over 40 years of experience. From web development to marketing strategy, we empower businesses to thrive in the digital age.' />
          {/* <link rel="canonical" href="" /> */}
          <meta name="keywords" content="360 Digital Marketing Services in Toronto"></meta>
        </Helmet>
        <HeroBanner 
          subHeading=""
          Heading="360 Digital Marketing"
          nextLineSpanHeading=""
          Paragraph="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence,"
          btnText=""
          bgImg="/images/creatives/digital-hero-bg.jpg"
          btn={false}
          btnRef="/"
          bgEffect={true}
          isCheveron={true}
        />
        
<div className='left-gradient-upper'>
  
        <MarketingCard
            
        />
  
    </div>
        <Footer/>
  </div>
    </>
  )
}

export default DigitalMarketing
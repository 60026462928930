import React,{useState} from "react";
import "./Footer.css"
import { Link,useNavigate,NavLink } from "react-router-dom";
import {Spinner} from "react-bootstrap"
import axios from "axios"

function Footer({
    newsletterSection=true
}) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };
  return (
    <>
    <div className="form container">
        {newsletterSection && 
            <div className="container-fluid">
                <div className="form-container w-75 m-auto px-2">
                <div className="text-container text-center">
                    <p className="sub-heading" style={{color:"white"}}>Subscribe Now</p>
                    <h3 className="body-heading" style={{color:"white"}}>Join Our Newsletter</h3>
                </div>
                <form className='inp-container d-md-flex d-none align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                    <input 
                         required
                         type="email"
                         value={email}
                         maxLength="56"
                         onChange={(e) => setEmail(e.target.value)}
                        placeholder='Your Email Address'
                    />
                    <button className='inp-btn' type="submit">Sign Up
                        {loading ? (
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                            />
                        ) : (
                        success ? (
                            <div >
                            {setLoading(false)}
                            {alert(success)}
                            {setSuccess(false)}
                            </div>
                        ) : (
                            error && (
                            <div>
                                {setLoading(false)}
                                {alert(error)}
                                {setError(false)}
                            </div>
                            )
                        )
                        )}
                    </button>
                </form>
                <form className='inp-container-resp d-md-none d-flex flex-column align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                    <input 
                     required
                     type="email"
                     value={email}
                     maxLength="56"
                     onChange={(e) => setEmail(e.target.value)}
                    placeholder='Your Email Address'/>
                    <button className='inp-btn' type="submit">Sign Up
                    {loading ? (
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                            />
                        ) : (
                        success ? (
                            <div >
                            {setLoading(false)}
                            {alert(success)}
                            {setSuccess(false)}
                            </div>
                        ) : (
                            error && (
                            <div>
                                {setLoading(false)}
                                {alert(error)}
                                {setError(false)}
                            </div>
                            )
                        )
                        )}
                    </button>
                </form>
                </div>
            </div>
        }
    </div>
    <div className={`footer-container ${!newsletterSection && "pt-0"}`}>
        <div className="container">
        {/* newsletter Upper section */}
        <div className="upper-newsletter-container container-fluid mx-auto" style={{width:"90%"}}>

            <div className="row d-flex align-items-center gy-5">
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-3">
                            <img  src="/images/icons/nav-logo.svg" alt="navigation logo" className="img-fluid" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
                        </div>
                        <div className="text-content">
                            <p>Empowering your businesses to leverage cutting-edge IT solutions for growth and success for 40+ years now</p>
                        </div>
                    
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-md-evenly justify-content-center">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            <NavLink to={"/"} className="anchor" exact activeClassName="active">
                                Home
                            </NavLink>
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                                About
                            </NavLink>
                            <NavLink to={"/services"} className="anchor" exact activeClassName="active">
                                Services
                            </NavLink>
                            <NavLink to={"/blog"} className="anchor" exact activeClassName="active">
                                Resources
                            </NavLink>
                            <NavLink to={"/contact"} className="anchor" exact activeClassName="active">
                                Contact Us
                            </NavLink>
                        </div>
                        <div className="text-md-start text-center d-flex flex-column  gap-3">
                            {/* <Link to={"/"} className="anchor" exact activeClassName="active">
                            IT Plan Customizer
                            </Link>  */}
                            <NavLink to={"/privacy-ploicy"} className="anchor" exact activeClassName="active">
                            Privacy Policy
                            </NavLink> 
                            {/* <p className="anchor mb-0">Terms & Conditions</p>
                            <p className="anchor mb-0">Cookie Policy</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="info-container">
                       <div className="d-flex flex-column align-items-center justify-content-start gap-3">
                            <div className="d-flex align-items-center gap-2 w-100">
                                <img src="/images/icons/footer-phone.svg" alt="phone icon"/>
                                <div>
                                    <a href="tel:+16474794288" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>(647) 479 4288</a>
                                    
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2  w-100">
                                <img src="/images/icons/footer-mail.svg" alt="mail icon"/>
                                <div>
                                <a href="mailto:info@hqns.ca" className="body-heading info-content" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>info@hqns.ca</a>
                                    {/* <p className="body-heading info-content"></p> */}
                                </div>
                            </div>
                            <div className="d-flex align-items-start gap-2 w-100">
                                <img src="/images/icons/footer-location.svg" alt="location icon"/>
                                <div>
                                    
                                    <p className="body-heading info-content">2601 Matheson Blvd East | Suite 206 Mississauga, ON | L4W 5A8</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}
        <div className="my-5"></div>

        {/* Lower sections */}

        <div className="lower-section text-center py-2">
            <div className="lower-container container flex-column gap-3 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© Copyright 2024. All Rights Reserved by HQNS</p>
                <div className="social-link">
                    <a href="https://www.facebook.com/hqnetworksolutions/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/fb-icon.svg" alt="facebook icon" />
                    </a>
                    <a href="https://at.linkedin.com/company/hqns" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/linked-icon.svg" alt="linked in icon" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer

function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card">
        <div className="testimonial-card-info pb-2">
          <div className="profile-info d-flex flex-column align-items-start justify-content-between gap-4">
              <img src="/images/icons/Saying-quotes.svg" alt="qoutation icon" className="user-img"/>
              <h5 className="sub-heading mb-0">{props.clientName}</h5>
              <p className="body-paragraph text-start mb-0">{props.clientReview}</p>
          </div>
        </div>
    </div>
  )
}

export default TestimonialCard
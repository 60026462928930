import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Footer from '../component/Footer/Footer'
import DiscoverImageServices from "../component/DiscoverImageServices/DiscoverImageServices"
import { Helmet } from 'react-helmet'

function Services() {
    return (
      <div className="home-page">
        <Helmet>
          <title>Innovative IT Solutions | HQNS</title>
          <meta name='description' content='Stay ahead in the dynamic tech landscape with HQNS. Explore our 40+ years of expertise in co-managed IT, audits, strategic project management, cloud services, security, and more.' />
          {/* <link rel="canonical" href="" /> */}
        </Helmet>
        <HeroBanner 
          subHeading=""
          Heading="Our Services"
          nextLineSpanHeading=""
          Paragraph="In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach. With over 40 years of collective experience, our team embodies a rich tradition of technological excellence,"
          btnText=""
          bgImg="/images/creatives/services-hero-bg.jpg"
          btn={false}
          btnRef="/"
          bgEffect={true}
          isCheveron={true}
        />

<div className='left-gradient'>
        <DiscoverImageServices
            heading="Pioneering IT Solutions for Tomorrow’s Business Landscape"
            subHeading="OUR SERVICES"
            content={"In a world continuously shaped and reshaped by technological advancements, having a reliable IT partner can be your key to staying ahead. At HQNS, we bring to you over 40 years of experience in carving out IT pathways that are innovative, secure, and perfectly aligned with your business goals.\n Discover our spectrum of services, each designed to empower your business with a technological edge that is both contemporary and ready for the future:"}
        />
</div>

<div className='right-gradient'> 
        <TextContent
           heading= "Tailored Solutions, Just a Click Away"
           subHeading=""
           content="At HQNS, we understand that every business is unique. That’s why we are dedicated to providing solutions that are crafted with your specific needs in mind. Reach out to us to discuss how we can help you forge a path to success with IT solutions that are modern, reliable, and personalized."
           btn={true}
           btnText="Get Started"
           btnRef='/contact'
           shiftRow={false}
           img="/images/creatives/services-text-1.png"
           reviewContent={false}
        />
  
       
        <CardCarousel
          subHeading="OUR NEWS"
          heading="Most Popular Blogs Publications"
          content={"In the dynamic world of technology, HQNS has carved out a space defined by reliability, proficiency, and a client-centered approach"}
        />
</div>
  
  
        <Footer/>
  </div>
    )
  }
  
  export default Services
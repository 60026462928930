import React,{useState} from "react";
import "./LandingFooter.css"
import { NavLink, useNavigate } from "react-router-dom";

function LandingFooter() {
    const navigate = useNavigate()
  return (
    <>
    <div className={`LandingFooter-container `}>
        <div className="container">

            <div className="text-center">
                <div className="newsletter-logo img-fluid mb-3">
                    <img  src="/images/icons/nav-logo.svg" alt="navigation logo" className="img-fluid" style={{cursor:"pointer"}}/>
                </div>
                <p className="body-paragraph w-75 m-auto my-4">HQ Network Solutions, where innovation meets expertise. We deliver co-managed IT services, strategic IT project management, and security & compliance solutions. Move forward confidently with us.</p>

                <div className="info-container mt-4">
                    <p className="body-paragraph info-content">2601 Matheson Blvd East | Suite 206 Mississauga, ON | L4W 5A8</p>
            
                
                    <div className="mt-3">
                        <a href="tel:+16474794288" className="body-paragraph info-content " style={{textDecoration:"none",cursor:"pointer"}}>(647) 479 4288</a>
                    </div>
                    <br></br>
                    <div className="mb-3">
                        <a href="mailto:info@hqns.ca" className="body-paragraph info-content my-4" style={{textDecoration:"none",cursor:"pointer"}}>info@hqns.ca</a>
                    </div>       
                </div>
            </div>
            </div>
        <div className="my-5"></div>


        <div className="lower-section text-center py-2">
            <div className="lower-container container flex-column gap-3 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            <p className="mb-0">© Copyright 2024. All Rights Reserved by HQNS</p>
                <div className="social-link">
                    <a href="https://www.facebook.com/hqnetworksolutions/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/fb-icon.svg" alt="facebook icon" />
                    </a>
                    <a href="https://at.linkedin.com/company/hqns" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/linked-icon.svg" alt="linked in icon" />
                    </a>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default LandingFooter
import './App.css';
import "./bootstrap.min.css"
import Home from './screens/Home';
import About from './screens/About';
import Services from './screens/Services';
import { Routes, Route , Navigate } from 'react-router-dom';
import Contact from './screens/Contact';
import ManagedService from './screens/ManagedService';
import DigitalMarketing from './screens/DigitalMarketing';
import Blog from './screens/Blog';
import BlogInner from './screens/BlogInner';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import ServicesInner from './screens/ServicesInner';
import ManagedItComplience from './screens/ManagedItComplience';
import ManagedCloudServices from './screens/ManagedCloudServices';
import ITStrategicProjectManagment from './screens/ITStrategicProjectManagment';
import ITAudits from './screens/ITAudits';
import CoManagedITServices from './screens/CoManagedITServices';

import PrivacyPolicy from "./screens/PrivacyPolicy"

import NotFound from './screens/NotFound';

import ThankYou from './screens/ThankYou';

import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import LandingPage from './screens/LandingPage';


function App() {
  return (
    <div style={{backgroundColor:"#09193A"}}>
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true} />}/>
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogs/:id" element={<BlogInner />} />

        {/* Services individual */}
        <Route path="/360-digital-marketing" element={<DigitalMarketing />} />
        <Route path="/managed-it-services" element={<ManagedService />} />
        <Route path="/managed-it-security-and-compliance" element={<ManagedItComplience />} />
        <Route path="/managed-cloud-services" element={<ManagedCloudServices />} />
        <Route path="/it-strategic-project-managment" element={<ITStrategicProjectManagment />} />
        <Route path="/it-audits" element={<ITAudits />} />
        <Route path="/co-managed-it-services" element={<CoManagedITServices />} />
       
        <Route path="/privacy-ploicy" element={<PrivacyPolicy />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>

        <Route path="/it-services" element={<LandingPage />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

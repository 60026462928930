import React from 'react'
import "./MarketingCard.css"

const cardData= [
    {
        img:'/images/icons/digital-card-1.svg',
        title:"WEB DEVELOPMENT",
        content:"Craft a sleek, responsive website that resonates with your audience. HQNS builds dynamic & functional websites tailored to your brand & goals. Let's turn clicks into conversions."
    },
    {
        img:'/images/icons/digital-card-2.svg',
        title:"APPLICATION DEVELOPMENT",
        content:"Streamline operations & elevate user experience with custom applications. HQNS designs & develops innovative apps that boost efficiency & productivity. Unleash the power of automation."
    },
    {
        img:'/images/icons/digital-card-3.svg',
        title:"MARKETING STRATEGY",
        content:"Navigate the digital landscape with clarity & purpose. HQNS develops data-driven marketing strategies that attract, engage, & convert your target audience. Grow your brand & achieve lasting impact."
    },
    {
        img:'/images/icons/digital-card-4.svg',
        title:"UI/UX DEVELOPMENT",
        content:"Design is more than aesthetic, it's about connection. HQNS creates intuitive & user-friendly interfaces that guide users seamlessly towards your goals. Optimize the journey, maximize results."
    },
    {
        img:'/images/icons/digital-card-5.svg',
        title:"LOGO & BRANDING",
        content:"Make a lasting impression with a brand identity that tells your story. HQNS designs unique logos & crafting cohesive brand experiences that solidify your position in the market. Stand out, be remembered."
    },
    {
        img:'/images/icons/digital-card-7.svg',
        title:"SOCIAL MEDIA MARKETING",
        content:"Amplify your brand voice & connect with your audience in real-time. HQNS curates engaging social media content & strategies that build communities & drive targeted engagement. Spark conversations, ignite your brand."
    },
    {
        img:'/images/icons/digital-card-9.svg',
        title:"EMAIL MARKEING",
        content:"Nurture leads & foster loyalty with personalized email campaigns. HQNS crafts compelling email sequences that convert prospects into customers & keep them coming back for more. Build relationships, drive results."
    },
    {
        img:'/images/icons/digital-card-8.svg',
        title:"SEARCH ENGINE OPTIMIZATION",
        content:"Dominate search results & attract organic traffic with strategic SEO. HQNS optimizes your website & content for visibility, propelling your brand to the top of relevant searches. Be found, be chosen."
    },
    {
        img:'/images/icons/digital-card-6.svg',
        title:"CONTENT MARKETING",
        content:"Inform, engage, & establish thought leadership with captivating content. HQNS creates strategic content that attracts qualified leads, educates your audience, & fuels brand growth. Tell your story, captivate your audience."
    },
]

function CardBox({
    img="",
    title='',
    content=""
})
{
    return(
    <div className='card-box text-center mx-auto'>
        <img src={img} alt='img'/>
        <h4 className='sub-heading font-noto my-2 mt-4'>{title}</h4>
        <p className='body-paragraph mb-2 px-2'>{content}</p>
    </div>
    )
}

function MarketingCard() {
  return (
    <div className='MarketingCard-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-5'>
                {
                    cardData.map((ele)=>{
                        return(
                            <div className='col-xl-4 col-md-6 col-12'>
                                <CardBox
                                    img={ele.img}
                                    title={ele.title}
                                    content={ele.content}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default MarketingCard